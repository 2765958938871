import { moduleLogger } from "../util";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";

const log = moduleLogger.scope("tracking");

type TrackingFeatures = [TrackingFeature, ...ChildTrackingFeature[]];

export class FeatureTrackingData {
	constructor(
		private readonly name: TrackEventAction,
		private readonly features: TrackingFeatures,
	) {}
}

export class TrackingFeature {
	constructor(
		protected readonly id: string,
		protected readonly name: string,
		protected readonly status: BenefitVisibilityStatus,
		protected readonly position: number,
		protected labels: Record<string, string[]>,
	) {}

	getId(): string {
		return this.id;
	}
}

export class MergeTrackingData {
	constructor(
		private readonly id: string,
		private readonly name: string,
		private readonly status: string,
		protected labels: Record<string, string[]>,
	) {}
}

export class ChildTrackingFeature extends TrackingFeature {
	constructor(
		override readonly id: string,
		override readonly name: string,
		override readonly status: BenefitVisibilityStatus,
		override readonly position: number,
		override readonly labels: Record<string, string[]>,
		readonly parentId: string,
	) {
		super(id, name, status, position, labels);
	}
}

export type TrackEventAction = "click" | "copy" | "close" | "open" | "scroll" | "next";
export type BenefitVisibilityStatus = "visible" | "loaded" | "hidden";

export function buildTrackingPayloadCinema(status: BenefitVisibilityStatus, cinemaDom: HTMLElement, featureOrder: number): TrackingFeature | undefined {
	const cinemaTrackingData = cinemaDom.dataset.cinemaTracking;
	if (cinemaTrackingData) {
		const cinemaTrackingLabels: Record<string, string[]> = JSON.parse(cinemaTrackingData) as Record<string, string[]>;
		log.info("cinema tracking:", cinemaTrackingData);
		return new TrackingFeature("FT9-benefit-main", "BenefitCinema", status, featureOrder, cinemaTrackingLabels);
	}
}

export class Tracking {
	constructor(
		readonly id: string,
		readonly name: string,
		readonly labels: Record<string, string[]>,
	) {}
}

export function buildTrackingPayloadBenefit(status: BenefitVisibilityStatus, benefitDom: HTMLElement, parentId: string): ChildTrackingFeature | undefined {
	const benefitPosition = benefitDom.getAttribute("data-benefit-position") ?? "0"; /*                                                                */

	const benefitTrackingData = benefitDom.dataset.tracking;
	if (benefitTrackingData) {
		const tracking: Tracking = JSON.parse(benefitTrackingData) as Tracking;
		log.info("benefit tracking:", benefitTrackingData);
		return new ChildTrackingFeature(`${tracking.id}${benefitPosition}`, tracking.name, status, Number.parseInt(benefitPosition), tracking.labels, parentId);
	}
}

export class TrackingService {
	sendEvent(payload: FeatureTrackingData) {
		log.info(`Send 'event'`, payload);
		eventQBus.emit("tracking.bct.submitAction", {}, payload);
	}

	sendMergeForCinema(payload: (TrackingFeature | ChildTrackingFeature)[]) {
		log.info(`Send 'merge for cinema'`, payload);
		eventQBus.emit("tracking.bct.addFeaturesToPageImpression", payload);
	}
}
